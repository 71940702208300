
#sortIcon {
    width: 2.8rem;
    height: auto;
    margin-right: 1.2rem;
    margin-left: .8rem;
    filter: drop-shadow(2px 2px 2px rgb(50, 50, 50));
    cursor: pointer;
}

#filterAndSearchRow {
    margin-top: 1rem;
    z-index: 100;
    top: 8rem;
}

#searchIcon {
    width: 2rem;
    height: auto;
}

#searchInputGroup {
    background-color: white !important;
}

#searchInput {
    margin-right: 1rem;
}

.dropdown-toggle::after {
    display: none !important; 
}