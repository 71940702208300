#headerRowAllMessages {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 2rem 0rem 1.6rem 2rem;
}

#messageIcon {
    width: 5rem;
    height: auto;
}

#roomName {
    margin-top: auto;
    margin-bottom: auto;
}