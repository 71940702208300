#landingpageContainer {
    padding: 2rem;
}

#chatBubbles {
    width: 100%;
    padding: 4rem 0rem 4rem 0rem;
    max-width: 40rem;
}

.landingPageCol {
    padding: 0rem 2rem 0rem 2rem;
    margin-right: auto !important;
    margin-left: auto !important;
    text-align: center;
}

#connectBtn {
    float: right !important;
    padding: .5rem 2rem .5rem 2rem;
}

#connectContainer {
    margin-top: 4rem;
    max-width: 40rem;
    text-align: left;
}