#geoIcon {
    width: 5rem;
    height: auto;
}

#roomName {
    margin-top: auto;
    margin-bottom: auto;
}
#headerRow {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1rem .8rem 1rem .8rem;
}