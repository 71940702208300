.roomRaw {
    padding: 0rem 0rem 0rem .5rem;
    background-color: #7697B5;
    margin-top: 1.3rem !important;
    box-shadow: 4px 4px 4px rgb(50, 50, 50);
}
.link {
    text-decoration: none;
    cursor: pointer;
}

.roomStatusIcon {
    padding: 1rem;
    width: 4.5rem !important;
    height: auto;
    filter: drop-shadow(2px 2px 2px rgb(50, 50, 50));
}

#roomName {
    margin-top: auto;
    margin-bottom: auto;
}

#threeDots {
    width: 2rem !important;
    height: auto;
}

#threeDots:hover {
    filter: opacity(60%) invert(30%);
}

#deleteBtn {
    height: 100%;
    background-color: crimson;
    text-align: center;
}
#deleteText {
    margin-top: auto;
    margin-bottom: auto;
}

.newMessages {
    margin-top: -.5rem !important;
    float: right !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    padding: .1rem !important;
    background-color: red;
    border-radius: 50%;
    text-align: center;
}