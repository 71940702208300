#userIcon {
    width: 5rem;
    height: auto;
}

#userName {
    margin-top: auto;
    margin-bottom: auto;
}

.white {
    border-top: 1px solid rgb(255, 255, 255);
    opacity: inherit;
}

.statusIcon {
    width: 3rem;
    height: auto;
    padding: 5px;
}

.roomRaw {
    padding: .7rem .7rem .7rem .5rem;
    background-color: #7697B5;
    margin-top: 1.3rem !important;
    box-shadow: 4px 4px 4px rgb(50, 50, 50);
    cursor: pointer;
}

.roomStatusIcon {
    width: 4.5rem !important;
    height: auto;
    filter: drop-shadow(2px 2px 2px rgb(50, 50, 50));
}