.black {
    border-top: 1px solid rgb(0, 0, 0);
    opacity: inherit;
}

#geoIcon {
    width: 5rem;
    height: auto;
}

#roomName {
    margin-top: auto;
    margin-bottom: auto;
}